// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categorias-blog-js": () => import("./../../../src/pages/categorias-blog.js" /* webpackChunkName: "component---src-pages-categorias-blog-js" */),
  "component---src-pages-categorias-creators-js": () => import("./../../../src/pages/categorias-creators.js" /* webpackChunkName: "component---src-pages-categorias-creators-js" */),
  "component---src-pages-conversas-js": () => import("./../../../src/pages/conversas.js" /* webpackChunkName: "component---src-pages-conversas-js" */),
  "component---src-pages-diretorio-js": () => import("./../../../src/pages/diretorio.js" /* webpackChunkName: "component---src-pages-diretorio-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-guias-1-js": () => import("./../../../src/pages/guias_1.js" /* webpackChunkName: "component---src-pages-guias-1-js" */),
  "component---src-pages-hibridos-js": () => import("./../../../src/pages/hibridos.js" /* webpackChunkName: "component---src-pages-hibridos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inscricao-js": () => import("./../../../src/pages/inscricao.js" /* webpackChunkName: "component---src-pages-inscricao-js" */),
  "component---src-pages-referencias-js": () => import("./../../../src/pages/referencias.js" /* webpackChunkName: "component---src-pages-referencias-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-todos-js": () => import("./../../../src/pages/todos.js" /* webpackChunkName: "component---src-pages-todos-js" */),
  "component---src-templates-all-post-contentful-js": () => import("./../../../src/templates/all-post-contentful.js" /* webpackChunkName: "component---src-templates-all-post-contentful-js" */),
  "component---src-templates-categorias-author-js": () => import("./../../../src/templates/categorias-author.js" /* webpackChunkName: "component---src-templates-categorias-author-js" */),
  "component---src-templates-diretorio-post-contentful-js": () => import("./../../../src/templates/diretorio-post-contentful.js" /* webpackChunkName: "component---src-templates-diretorio-post-contentful-js" */)
}

